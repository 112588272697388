<template>
<section class="aishop">
   <div class="banner">
      <img :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   </div>
   <section class="main_contain">
      <h1>{{ $t('PC.Product.AISHOP.3anq1t') }}</h1>
      <div>
         <article>
            <figure>
               <figcaption>
                  <p>{{ $t("PC.Product.AISHOP.hz1oeu") }}</p>
                  <i></i>
               </figcaption>
               <span>{{ $t("PC.Product.AISHOP.mrvi56") }}</span>
            </figure>
         </article>
         <img src="../../../assets/zh/images/suanfas_img1.jpg" alt="" />
      </div>
      <div>
         <article>
            <figure>
               <figcaption>
                  <p>{{ $t("PC.Product.AISHOP.czua2c") }}</p>
                  <i></i>
               </figcaption>
               <span>{{ $t("PC.Product.AISHOP.avfxgp") }}</span>
            </figure>
         </article>
         <img src="../../../assets/zh/images/suanfas_img2.jpg" alt="" />
      </div>
      <div>
         <article>
            <figure>
               <figcaption>
                  <p>{{ $t("PC.Product.AISHOP.yu3lnn") }}</p>
                  <i></i>
               </figcaption>
               <span>{{ $t("PC.Product.AISHOP.b9fkny") }}</span>
            </figure>
         </article>
         <img src="../../../assets/zh/images/suanfas_img3.jpg" alt="" />
      </div>
      <div>
         <article>
            <figure>
               <figcaption>
                  <p>{{ $t("PC.Product.AISHOP.p8wgaj") }}</p>
                  <i></i>
               </figcaption>
               <span>{{ $t("PC.Product.AISHOP.cbex8y") }}</span>
            </figure>
         </article>
         <img src="../../../assets/zh/images/suanfas_img4.jpg" alt="" />
      </div>
      <div>
         <article>
            <figure>
               <figcaption>
                  <p>{{ $t("PC.Product.AISHOP.nmthch") }}</p>
                  <i></i>
               </figcaption>
               <span>{{ $t("PC.Product.AISHOP.gvcc8r") }}</span>
            </figure>
         </article>
         <img src="../../../assets/zh/images/suanfas_img5.jpg" alt="" />
      </div>
      <div>
         <article>
            <figure>
               <figcaption>
                  <p>{{ $t("PC.Product.AISHOP.p6zqs6") }}</p>
                  <i></i>
               </figcaption>
               <span>{{ $t("PC.Product.AISHOP.q77kdm") }}</span>
            </figure>
         </article>
         <img src="../../../assets/zh/images/suanfas_img6.jpg" alt="" />
      </div>
      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "../components/FooterC";

export default {
   name: "AIShop",
   data() {
      return {
         banner: require('../../../assets/zh/images/suanfas_banner@2x.jpg'),
         banner_en: require('../../../assets/en/images/suanfas_banner@2x.jpg'),
      }
   },
   components: {
      FooterC
   }
};
</script>

<style lang="scss" scoped>
.aishop {
   margin-top: 70Px;
   word-break: break-word !important;

   .banner {
      display: block;
      position: absolute;
      left: 50%;
      top: 70Px;
      transform: translate(-50%, 0);
      overflow: hidden;

      img {
         width: 1920Px;
         height: 440Px;
      }
   }

   .main_contain {
      position: absolute;
      top: 510Px;
      width: 100%;
      background-color: #f5f6f9;

      h1 {
         height: 45Px;
         font-size: 32Px;
         color: #333;
         line-height: 45Px;
         text-align: center;
         letter-spacing: 2Px;
         margin: 70Px 0 40Px;
      }

      &>div {
         margin: 70Px auto;
         width: 960Px;
         // height: 290Px;
         background: #fff;
         border-radius: 4Px;
         box-sizing: border-box;
         padding: 50Px 58Px;
         font-weight: 600;
         display: flex;
         justify-content: space-between;

         article {
            figure {
               figcaption {
                  p {
                     height: 45Px;
                     font-size: 32Px;
                     color: #333300;
                     line-height: 45Px;
                     margin-top: 11Px;
                  }

                  i {
                     width: 43Px;
                     height: 4Px;
                     background: #003299;
                  }
               }

               span {
                  margin-top: 31Px;
                  display: block;
                  width: 454Px;
                  // height: 56Px;
                  font-size: 16Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  color: #666660;
                  line-height: 28Px;
                  font-weight: normal;
               }
            }
         }
      }
   }
}
</style>
